<script>
import { Bar } from "vue-chartjs/legacy";

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
  } from "chart.js";
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LogarithmicScale
  );

export default {
    extends: Bar,
    // mixins: [reactiveProp],
    props: {
        chartId: {
            type: String,
            default: 'bar-chart-base'
        },
        chartData: {
            type: Object,
            default: () => ({})
        },
        chartOptions: {
            type: Object,
            default: () => ({
                responsive: true,
                maintainAspectRatio: false
            })
        }
    },
    mounted () {
        this.renderChart(this.chartData, this.chartOptions) // maybe typo?
    },
    methods: {
        resize() {
            this.$data._chart.resize()
        }
    },
    watch: {
        chartData () {
            this.$data._chart.update()
        }
    }
}
</script>
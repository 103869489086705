// Create a hash function for strings.
// Converts the string to an integer.
function stringToHash(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++ ) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash = hash & hash;
    }
    return hash;
}

// Convert a hash value to an RGB color.
function hashToRgb(hash) {
    const r = (hash & 0xFF0000) >> 16;
    const g = (hash & 0x00FF00) >> 8;
    const b = hash & 0x0000FF;
    return `rgb(${r}, ${g}, ${b})`;
}

// Create a color based on a string (label)
export function labelToColor(label) {
    const hash = stringToHash(label);
    return hashToRgb(hash)
}
<template>
    <v-card class="mt-8">
      <v-card-title>Toxins Used</v-card-title>
      <v-card-text class="text-center">
        <doughnut-chart ref="toxinchart" v-if="toxinsloaded" :chartData="chartdata" :chartOptions="chartOptions" />
        <template v-else >
          <p>Loading data from server...</p>
          <v-progress-circular indeterminate size="100"/>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="downloadImg">Download Image</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import { get, call } from "vuex-pathify";
//   import BarChart from "@/components/charts/base/BarChart.vue"
  import DoughnutChart from "./base/DoughnutChart.vue";

  import { labelToColor } from "@/utils/colors.js"
  
  export default {
    name: "ToxinsChart",
    components: { DoughnutChart },
    data: function () {
      return {
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
        //   indexAxis: "x",
        },
        chartdata: {
          labels: [],
          datasets: [],
        },
      };
    },
    async mounted() {
      await new Promise((resolve) => setTimeout(resolve, 400));
      this.fillData();
    },
    computed: {
      ...get("chart", [
        "toxinsloaded",
        "country"
      ]),
    },
    methods: {
      ...call("chart", [
        "statCategory"
      ]),
      async fillData() {
        const dataset = await this.statCategory({country: this.country, category: 'toxins'})
        const backgroundColors = dataset.labels.map(label => labelToColor(label))
        this.chartdata = {
          labels: dataset.labels,
          datasets: [
            {
              label: "Toxins",
              data: dataset.series,
              backgroundColor: backgroundColors
            },
          ]
        }
      },
      downloadImg () {
        const canvas = this.$refs.toxinchart.$refs.canvas.toDataURL('image/png')  
        var a = document.createElement('a');
        a.href = canvas
        a.download = `toxin-chart_${this.country}.png`
        a.click()
      }
    },
  };
  </script>
  
<template>
  <v-card class="mt-8">
    <v-card-title>Cases to Court</v-card-title>
    <v-card-text class="text-center">
      <doughnut-chart ref="courtchart" v-if="courtloaded" :chartData="chartdata" :chartOptions="chartOptions" />
      <template v-else >
        <p>Loading data from server...</p>
        <v-progress-circular indeterminate size="100"/>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="downloadImg">Download Image</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { get, call } from "vuex-pathify";
import DoughnutChart from "./base/DoughnutChart.vue";

import { labelToColor } from "@/utils/colors.js"

export default {
  name: "CourtChart",
  components: { DoughnutChart },
  data: function () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      chartdata: {
        labels: [],
        datasets: [],
      },
    };
  },
  async mounted() {
    await new Promise((resolve) => setTimeout(resolve, 400));
    this.fillData();
  },
  computed: {
    ...get("chart", [
      "courtloaded",
      "country"
    ]),
  },
  methods: {
    ...call("chart", [
      "statCategory"
    ]),
    async fillData() {
      const dataset = await this.statCategory({country: this.country, category: 'court'})
      const backgroundColors = dataset.labels.map(label => labelToColor(label))
      this.chartdata = {
        labels: dataset.labels,
        datasets: [
          {
            label: "Court",
            data: dataset.series,
            backgroundColor: backgroundColors
          },
        ]
      }
    },
    downloadImg () {
      const canvas = this.$refs.toxinchart.$refs.canvas.toDataURL('image/png')  
      var a = document.createElement('a');
      a.href = canvas
      a.download = `court-chart_${this.country}.png`
      a.click()
    }
  },
};
</script>

<template>
    <v-container fluid>
        <v-card>
            <!-- <v-card-title>Look for any Incident</v-card-title> -->
            <v-card-title>
                <v-text-field 
                    label="Search terms"
                    v-model="query"
                    clearable
                    @keyup.enter="search"
                    class="mr-10"
                />
                <v-btn @click="search">Search</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="indexes"
                    :items-per-page="20"
                    class="elevation-1"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    show-select
                    :loading="loading"
                    :footer-props="{'items-per-page-options':[20, -1]}"
                
                    @select-all="selectAll"
                >
                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr>
                            <th :colspan="headers.length">
                            <v-btn class="mt-2 mb-2" 
                                color="guacamole"
                                @click.prevent="exportSelect"
                                :disabled="haveSelection">
                                <span v-if="!exportloading">Export SEL</span>
                                <v-progress-circular indeterminate v-else />
                            </v-btn>
                            </th>
                        </tr>
                    </thead>
                </template>
                    <template v-slot:item.TypeOfAnimal="{ item }">
                    {{ splitSpecies(item.TypeOfAnimal)}}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="viewItem(item)">
                            mdi-eye
                        </v-icon>
                        <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="getConfirm(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        

        <!-- START - CONFIRM DELETE DIALOG -->
        <v-dialog
            v-model="deletedialog"
            persistent
            width="350"
            :ripple="foo"
            style="z-index: 1301"
        >
            <v-card>
                <v-toolbar color="warning" class="justify-center mb-2 rounded-0">
                    <v-toolbar-title>Confirm Incident Delete</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <p>You are about to delete an Incident. If you are sure,
                        type the following string:</p>
                    <p class="justify-center text-center" style="background-color: #e6e6e6">
                        <pre>{{ todelete }}</pre>
                    </p>
                    <p>in the text input below and press confirm.</p>
                    <v-form
                        v-model="valid"
                        ref="confirmform"
                        lazy-validation
                    > 
                        <v-text-field
                            v-model="deletecode"
                            :rules="confirmRule"
                        />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="error" @click.stop="delcancel">Cancel</v-btn>
                    <v-btn color="warning" @click.stop="delconfirm">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- END - CONFIRM DELETE DIALOG -->
    </v-container>
  </template>
  
  <script>
  import { get, call } from 'vuex-pathify'
  import ExportFileService from '../services/ExportFileService'

  export default {
    data () {
        return {
            query: '',
            selected: [],
            sortBy: 'entrycode',
            sortDesc: true,
            exportloading: false,
            // START DELETE DIALOG
            valid: false,
            deletedialog: false,
            foo: false,
            todelete: "",
            deluserid: "",
            deletecode: ""
            // END DELETE DIALOG
        }
    },
    computed: {
        ...get('search', [
            'loading',
            'indexes'
        ]),
        headers () {
          return [
              {
                  text: 'EntryCode',
                  align: 'start',
                  sortable: true,
                  value: 'entrycode'
              },
              {
                  text: "Notification Date",
                  value: "dateOfEvent",
                  sortable: true,
                  sort: (a, b) => {
                    let _a = a
                    let _b = b
                    if (!a) {
                      _a = '01/01/1900'
                    }
                    if (!b) {
                      _b = '01/01/1900'
                    }
                    return DateTime.fromFormat(_a, "dd/MM/yyyy") - DateTime.fromFormat(_b, "dd/MM/yyyy")
                  }
              },
              {
                  text: "Country",
                  value: "country"
              },
              {
                  text: "Region",
                  value: "region"
              },
              {
                  text: "Municipality",
                  value: "municipality"
              },
              {
                  text: "Species found dead",
                  value: "TypeOfAnimal"
              },
              {
                  text: "Poison bait found",
                  value: "poisonBaitFound"
              },
              {
                  text: "Autopsy and/or tox. analyses carried out",
                  value: "autopsyAndMore"
              },
              {
                  text: "Antipoison Dog Unit used",
                  value: "antipoisonDogUnitUsed"
              },
              {
                  text: "Live",
                  value: "live"
              },
              {
                  text: "Actions",
                  value: 'actions',
                  sortable: false
              }
          ]
        },
        confirmRule() {
            return [
                () => (this.todelete === this.deletecode) || 'Value does not match requested code',
                v => !!v || 'Fill value to confirm'
            ];
        },
        haveSelection () {
            return this.selected.length == 0 ? true : false
        }
    },
    methods: {
        ...call('search', [
            'doSearch'
        ]),
        viewItem (item) {
            this.$router.push({name: 'ViewIncident', params: {'entrycode': item.entrycode }})
        },
        editItem (item) {
            this.$router.push({name: 'Informant', params: {'entrycode': item.entrycode}})
        },
        selectAll(items) {
            // console.log('this is called as well, selectAll')
            if (this.selected.length === 0) {
                this.selected = items.slice();
                console.log('this is within the if')
            } else {
                console.log('this is inside the else')
                this.selected = []
            }
        },
        splitSpecies (item) {
            if(!item) return 'n/a'
            if (item == '#') {
                return 'n/a'
            }
            return item.split('#').join(', ')
        },
        async search () {
            this.doSearch(this.query)
        },
        async exportSelect () {
            this.exportloading = true
            let selection = this.selected.map((item) => { return item.entrycode})
            ExportFileService.exportSelection(selection, this.$store.state.newauth.token).then((res) => {
                var FILE = window.URL.createObjectURL(
                    new Blob([res.data],
                    { type: 'application/vnd.ms-excel'})
                )
                this.exportloading = false;
                var docUrl = document.createElement('a');
                docUrl.href = FILE;
                docUrl.setAttribute('download', `export-selection-${new Date().toLocaleDateString()}.xlsx`);
                document.body.appendChild(docUrl)
                docUrl.click();
            }).catch((error) => {
                console.log(error)
            })

        },
        // START CONFIRM DELETE METHODS
        getConfirm (item) {
        this.todelete = `delete ${item.entrycode}`
        this.delentrycode = item.entrycode
        this.deletedialog = true
        },
        delcancel () {
            this.$refs.confirmform.resetValidation()
            this.deletecode = ""
            this.todelete= ""
            this.delentrycode = ""
            this.deletedialog = false
        },
        delconfirm () {
            if (this.$refs.confirmform.validate()) {
                this.deletecode = ""
                // delete the user
                this.$store.dispatch('incidentsnew/deleteIncident', this.delentrycode)
                    .then(console.log(true))
                    .catch(e => {
                        console.log(e)
                    })
            } else {
                this.foo = true
                console.log('the validation is not correct')
            }
            this.deletecode = ""
            this.delentrycode = ""
            this.$refs.confirmform.resetValidation()
            this.deletedialog = false
        },
        // END CONFIRM DELETE METHODS
    }
  }
  </script>
  
  <style scoped>
  </style>
<template>
    <v-card class="mt-8">
      <v-card-title>Incidents per Year</v-card-title>
      <v-card-text class="text-center">
        <bar-chart ref="yearchart" v-if="yearloaded" :chartData="chartdata" :chartOptions="chartOptions" />
        <template v-else >
          <p>Loading data from server...</p>
          <v-progress-circular indeterminate size="100"/>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="downloadImg">Download Image</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import { get, call } from "vuex-pathify";
  import BarChart from "@/components/charts/base/BarChart.vue"
  
  export default {
    name: "YearChart",
    components: { BarChart },
    data: function () {
      return {
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: "x",
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true
            }
          }
        },
        chartdata: {
          labels: [],
          datasets: [],
        },
      };
    },
    async mounted() {
      await new Promise((resolve) => setTimeout(resolve, 400));
      this.fillData();
    },
    computed: {
      ...get("chart", [
        "yearloaded",
        "country"
      ]),
    },
    methods: {
      ...call("chart", [
        "statCategory"
      ]),
      async fillData() {

        const dataset = await this.statCategory({country: this.country, category: 'year'})
        this.chartdata = {
          labels: dataset.labels,
          datasets: [
            {
              label: "Incidents",
              data: dataset.series[0],
              backgroundColor: "#056dac"
            },
            {
              label: "Antipoison dog",
              data: dataset.series[1],
              backgroundColor: "#28afbb"
            },
          ]
        }
      },
      downloadImg () {
        const canvas = this.$refs.animalchart.$refs.canvas.toDataURL('image/png')  
        var a = document.createElement('a');
        a.href = canvas
        a.download = `year-chart_${this.country}.png`
        a.click()
      }
    },
  };
  </script>
  
<template>
    <v-container fluid>
        <v-card>
            <v-card-title>Select Country</v-card-title>
            <v-card-text>
                <v-select
                    v-model="country"
                    label="Country option list"
                    :items="countries"
                    required
                    dense
                    outlined
                    @change="updateGraphs"
                />
            </v-card-text>
        </v-card>
        <animals-chart :key="animalGraph"/>
        <toxins-chart :key="toxinsGraph" />
        <year-chart :key="yearGraph" />
        <month-chart :key="monthGraph" />
        <complaints-chart :key="complaintsGraph" />
        <court-chart :key="courtGraph" />
        <autopsy-chart :key="autopsyGraph" />
    </v-container>
  </template>
  
  <script>
  import { sync } from "vuex-pathify"
  import AnimalsChart from "../components/charts/AnimalsChart.vue";
  import AutopsyChartVue from '../components/charts/AutopsyChart.vue';
  import ComplaintsChartVue from '../components/charts/ComplaintsChart.vue';
  import CourtChartVue from '../components/charts/CourtChart.vue';
  import MonthChartVue from '../components/charts/MonthChart.vue';
  import ToxinsChart from "../components/charts/ToxinsChart.vue";
  import YearChartVue from '../components/charts/YearChart.vue';

  export default {
    components: {
        'animals-chart': AnimalsChart,
        'toxins-chart': ToxinsChart,
        'year-chart': YearChartVue,
        'month-chart': MonthChartVue,
        'complaints-chart': ComplaintsChartVue,
        'court-chart': CourtChartVue,
        'autopsy-chart': AutopsyChartVue
    },
    data () {
        return {
            animalGraph: '1',
            toxinsGraph: '2',
            yearGraph: '3',
            monthGraph: '4',
            complaintsGraph: '5',
            courtGraph: '6',
            autopsyGraph: '7',
            countries: [
                {
                    text: "All",
                    value: "all"
                },
                {
                    text: "Albania",
                    value: "Albania"
                },
                {
                    text: "Bosnia and Herzegovina",
                    value: "Bosnia"
                },
                {
                    text: "Bulgaria",
                    value: "Bulgaria"
                },
                {
                    text: "Croatia",
                    value: "Croatia"
                },
                {
                    text: "Greece",
                    value: "Greece"
                },
                {
                    text: "North Macedonia",
                    value: "North Macedonia"
                },
                {
                    text: "Serbia",
                    value: "Serbia"
                }
            ],
        }
    },
    async created () {
        await this.fetchall()
    },
    computed: {
        country: sync('chart/country'),
        animalsloaded: sync('chart/animalsloaded'),
        toxinsloaded: sync('chart/toxinsloaded'),
        yearloaded: sync('chart/yearloaded'),
        monthloaded: sync('chart/monthloaded'),
        complaintsloaded: sync('chart/complaintsloaded'),
        courtloaded: sync('chart/courtloaded'),
        autopsyloaded: sync('chart/autopsyloaded')
    },
    methods: {
        async fetchall () {
            this.$store.dispatch('chart/setAnimals', this.country)
            this.$store.dispatch('chart/setToxins', this.country)
            this.$store.dispatch('chart/setIncidentperyear', this.country)
            this.$store.dispatch('chart/setIncpermonth', this.country)
            this.$store.dispatch('chart/setIncComplaints', this.country)
            this.$store.dispatch('chart/setIncCourt', this.country)
            this.$store.dispatch('chart/setIncAutopsy', this.country)
        },
        async updateGraphs() {
            this.animalsloaded = false
            this.toxinsloaded = false
            this.yearloaded = false
            this.monthloaded = false
            this.courtloaded = false
            this.autopsyloaded = false
            this.renderGraphs()
            await this.fetchall()
        },
        renderGraphs () {
            this.animalGraph = Date.now()
            this.toxinsGraph = Date.now() + 1
            this.yearGraph = Date.now() + 2
            this.monthGraph = Date.now() + 3
            this.complaintsGraph = Date.now() + 4
            this.courtGraph = Date.now() + 5
            this.autopsyGraph = Date.now() + 6
        }
    }
  }
  </script>
  
  <style scoped>
  </style>
<template>
    <v-card class="mt-8">
      <v-card-title>Animals,&nbsp;<small>logarithmic scale</small></v-card-title>
      <v-card-text class="text-center">
        <bar-chart ref="animalchart" v-if="animalsloaded" :chartData="chartdata" :chartOptions="chartOptions" />
        <template v-else >
          <p>Loading data from server...</p>
          <v-progress-circular indeterminate size="100"/>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="downloadImg">Download Image</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import { get, call } from "vuex-pathify";
  import BarChart from "@/components/charts/base/BarChart.vue"
  
  export default {
    name: "AnimalsChart",
    components: { BarChart },
    data: function () {
      return {
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: "x",
          scales: {
            x: {
              display: true,
              // type: 'logarithmic'
            },
            y: {
              display: true,
              type: 'logarithmic'
            }
          }
        },
        chartdata: {
          labels: [],
          datasets: [],
        },
      };
    },
    async mounted() {
      await new Promise((resolve) => setTimeout(resolve, 400));
      this.fillData();
    },
    computed: {
      ...get("chart", [
        "animalsloaded",
        // "animals",
        "country"
      ]),
    },
    methods: {
      ...call("chart", [
        "animals",
        "statCategory"
      ]),
      async fillData() {

        // const dataset = await this.animals(this.country)
        const dataset = await this.statCategory({country: this.country, category: 'animals'})
        this.chartdata = {
          // labels: this.animals(this.country).labels,
          labels: dataset.labels,
          datasets: [
            {
              label: "Dogs",
              // data: this.animals(this.country).dataset[0].data,
              data: dataset.dataset[0].data,
              backgroundColor: "#0CAC5B"
            },
            {
              label: "Cats",
              // data: this.animals.dataset[1].data,
              // data: this.animals(this.country).dataset[1].data,
              data: dataset.dataset[1].data,
              backgroundColor: "#0CAC5B"
            },
            {
              label: "Wild",
              // data: this.animals.dataset[2].data,
              // data: this.animals(this.country).dataset[2].data,
              data: dataset.dataset[2].data,
              backgroundColor: "#ED7A40"
            },
            {
              label: "Birds",
              // data: this.animals.dataset[3].data,
              // data: this.animals(this.country).dataset[3].data,
              data: dataset.dataset[3].data,
              backgroundColor: "#FCBE3B"
            }
          ]
        }
      },
      downloadImg () {
        const canvas = this.$refs.animalchart.$refs.canvas.toDataURL('image/png')  
        var a = document.createElement('a');
        a.href = canvas
        a.download = `animal-chart_${this.country}.png`
        a.click()
      }
    },
  };
  </script>
  